import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import styles from './moolax.module.css';
import Slide from '../slide';
// assets
import moreFeaturesReleased from '../assets/more-features-released.svg';
import fasterDeployment from '../assets/faster-deployment.svg';

const Routes = require('../../routes').Routes;
interface ISlide {
  slideTitle?: string;
}

function Moolax({ slideTitle = 'Moolax' }: ISlide) {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "bg/moolax-bg.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image: file(relativePath: { eq: "moolax/cover.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const backgroundImageData = data.background.childImageSharp.fluid;
  const imageData = data.image.childImageSharp.fluid;

  return (
    <Slide
      title="Developing an MVP of a Smart Cashflow Manager"
      text="How Brocoders helped to build an MVP for a trendy FinTech startup offering a streamlined cash flow management solution for modern specialists and freelancers to drive their financial insights."
      backgroundImageData={backgroundImageData}
      imageData={imageData}
      imageClassName={styles.image}
      bgClassName={styles.background}
      bgColor="#9948ea"
      caseLink={Routes.CASE_STUDIES_MOOLAX}
    />
  );
}

export default Moolax;
